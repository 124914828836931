import { createStore } from 'vuex'
import { abi } from '@/ABI/abi'

export default createStore({
  state () {
    return {
      chain: 'mainnet',
      web3Provider: 'https://mainnet.infura.io/v3/becaa8e4944a48298f69fe93e8db06d7',
      contractAddrress: '0x1EA8dACedf6a8896E3E27fd2617e650C1f11f52E',
      abi: abi,
      dj: 'loading...'
    }
  },
  mutations: {
    setDj (state, payload) {
      state.dj = payload
    },
  }
})
